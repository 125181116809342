<script setup>
import NavLink from "@/Components/NavLink.vue";
import { router, usePage } from "@inertiajs/vue3";
import SplitButton from "primevue/splitbutton";
import Toolbar from "primevue/toolbar";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale, availableLocales } = useI18n({ useScope: "global" });

const page = usePage();
const logout = () => router.post("/logout");
const login = () => router.get("/login");

const items = ref([
    {
        label: page.props.auth.user ? t("nav.logout") : t("nav.login"),
        icon: page.props.auth.user ? "pi pi-sign-out" : "pi pi-sign-in",
        command: page.props.auth.user ? logout : login,
    },
]);
</script>
<template>
    <div class="min-h-full">
        <Toolbar>
            <template #start> </template>

            <template #center>
                <NavLink
                    v-if="page.props.auth.user"
                    href="/dashboard"
                    class="text-lg font-bold"
                    >Dashboard</NavLink
                >
                <NavLink v-else href="/login" class="text-lg font-bold"
                    >Login</NavLink
                >
            </template>

            <template #end>
                <NavLink v-if="page.props.auth.user" href="#" @click="logout"
                    ><span class="pi pi-sign-out"
                /></NavLink>
                <NavLink href="/login" label="Login" v-else
                    ><span class="pi pi-sign-in"
                /></NavLink>
            </template>
        </Toolbar>
        <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
            <div
                class="overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-center content-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
                <img src="/static/images/logo.png" class="" />
            </div>
        </div>
    </div>
</template>
